import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

export type RatingCard = {
  /** The mood of our Grumpy Cat */
  mood: "cautious" | "furious" | "grumpy" | "chilled";
  rating: string;
  subtitle: string;
};

const Rating = () => {
  /**
   * Cannot Be Dry, because useStaticQuery does
   * not work inside Storybook yet (without heavy modification)
   * and cannot create a storybook component
   * Source: https://github.com/gatsbyjs/gatsby/issues/26099
   */
  const images = useStaticQuery(graphql`
    {
      chilled: file(relativePath: { eq: "GrumpyPage/chilled.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      grumpy: file(relativePath: { eq: "GrumpyPage/grumpy.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      furious: file(relativePath: { eq: "GrumpyPage/furious.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      cautious: file(relativePath: { eq: "GrumpyPage/cautious.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const cards: RatingCard[] = [
    {
      mood: "chilled",
      rating: "0-10",
      subtitle: "Nothing to worry about here, everything seems calm.",
    },
    {
      mood: "cautious",
      rating: "10-12",
      subtitle:
        "Normal activity. If ratings show an upward trend over the next few days then it’s likely that Google’s mood is about to change.",
    },
    {
      mood: "grumpy",
      rating: "12-15",
      subtitle:
        "Google’s in a bit of a grump - rankings are changing more than usual.",
    },
    {
      mood: "chilled",
      rating: "15+",
      subtitle:
        "Easy tiger! Google is angry today with rankings fluctuating at high speed - it’s likely that an algorithm update is taking place.",
    },
  ];

  return (
    <div className="flex flex-col mt-12 gap-y-6">
      <h2 className="text-3xl text-white font-normal font-sans text-center">
        What Do Google’s Moods Mean?
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 justify-between">
        {cards.map(card => {
          const { mood, rating, subtitle } = card;
          return (
            <div className="flex flex-row bg-accuRankerPurple-500 border-2 border-gray-700 rounded-3xl px-5 py-6 gap-y-3">
              <div className="flex flex-col gap-y-3">
                <div className="flex flex-row gap-x-3">
                  <GatsbyImage
                    image={images[mood].childImageSharp.gatsbyImageData}
                    className="w-16 h-16"
                    alt={`${mood} tiger image`}
                  />
                  <div className="flex flex-col">
                    <span className="text-white">{rating}</span>
                    <span className="text-3xl text-accuRankerOrange-500 capitalize">
                      {mood}
                    </span>
                  </div>
                </div>
                <span className="text-gray-500 text-sm">{subtitle}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Rating;

const BASE_URL = `/api/grump/`;

type DataPoint = {
  x: number;
  y: number;
  color: string;
};

type UpdatePoint = {
  x: number;
  title: string;
  text: string;
};

export interface GrumpData {
  data: DataPoint[];
  updates: any;
}

const getGrumpStatus = (
  locale: string,
  device: string
): Promise<(GrumpData & { error: null }) | { error: string }> => {
  const url = `${BASE_URL}?code=${locale}&device=${device}`;
  return fetch(url)
    .then(response => response.json())
    .then((responseData: any) => {
      const data: DataPoint[] = responseData.data;
      const updates: UpdatePoint[] = responseData.updates;
      return {
        data,
        updates,
        error: null,
      };
    })
    .catch(er => ({
      error: er.toString(),
    }));
};

export default getGrumpStatus;

import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import { GatsbyImage } from "gatsby-plugin-image";
import Grump from "../components/Grump/Grump";
import BonnetCurves from "../images/Curves/FinishingDouble2Single.svg";
import { useStaticQuery, graphql } from "gatsby";
import BulletSection from "../ui-components/BulletSection";
import { DeepNonNullable } from "ts-essentials";
import { GrumpDataQuery } from "../../graphqlTypes";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody } from "@utils/mdxUtils";

const GrumpPage = () => {
  const data = useStaticQuery<DeepNonNullable<GrumpDataQuery>>(graphql`
    query GrumpData {
      strapiGrump {
        pageTitle
        childStrapiGrumpTitle {
          childMdx {
            body
          }
        }
        titleDescription
        bullets {
          title
          subtitle
          leftColumnTitle
          rightColumnTitle
          leftColumn {
            id
            title: description
          }
          rightColumn {
            id
            title: description
          }
        }
      }
      bigTiger: file(relativePath: { eq: "GrumpyPage/BigTiger.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const {
    pageTitle,
    titleDescription,
    bullets,
    childStrapiGrumpTitle: title,
  } = data.strapiGrump;

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block mt-10">
        <div className="flex flex-col items-start md:items-center text-center md:w-full md:p-20 bg-accuRankerPurple-900">
          <div id="title" className="text-left md:text-center text-white">
            <MDXRenderer>{mdxBody(title)}</MDXRenderer>
          </div>
          <div className="mt-10">
            <p className="text-left md:text-center text-base max-w-3xl text-gray-400">
              {titleDescription}
            </p>
          </div>
        </div>
      </div>
      <div className="div-block max-w-7xl z-10 relative overflow-visible">
        {/* Big Tiger */}
        <GatsbyImage
          image={data.bigTiger.childImageSharp.gatsbyImageData}
          className="hidden md:block absolute w-48 -right-3 -top-40"
          style={{ zIndex: -1, position: "absolute" }}
          alt="Grumpy Cat Image"
        />
        {/* Grumpy Panel */}
        <div className="rounded-2xl bg-accuRankerPurple-500 px-8 mb-4 mt-12 md:mt-0 lg:mx-auto py-8">
          <Grump />
        </div>
        {/* Bonnet Section */}
        <div className="relative pb-40 mt-12 md:-mt-4">
          <img
            src={BonnetCurves}
            className="bonnet-background-curve absolute h-full mx-auto left-0 right-0 z-0"
            alt="Bonnet Curvers Image"
          />
          <div className="md:pt-80 bonnet">
            <BulletSection {...bullets} />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default GrumpPage;

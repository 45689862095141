import React from "react";
/** To render custom tooltip */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactHighstock from "react-highcharts/ReactHighstock";
import { GrumpData } from "./GrumpService";
import { getStatusLabel } from "../../utils/grumpUtils";
import Cautious from "../../images/GrumpyPage/cautious.png";
import Chilled from "../../images/GrumpyPage/chilled.png";
import Furious from "../../images/GrumpyPage/furious.png";
import Grumpy from "../../images/GrumpyPage/grumpy.png";
import { renderToString } from "preact-render-to-string";

/**
 *
 * Helpers
 *
 */
const getBaseConfig = (turboThreshold: number) => ({
  chart: {
    alignTicks: false,
    // height: "100%",
    height: 549,
    backgroundColor: null,
    style: {
      fontFamily: "Galano",
    },
  },
  loading: {
    style: {
      fontSize: "28px",
    },
  },
  navigator: {
    enabled: true,
    maskFill: "rgba(252, 223, 193, 0.75)",
    series: {
      color: "transparent",
      lineColor: "#f89634",
    },
  },
  credits: false,
  plotOptions: {
    series: {
      cursor: "pointer",
      turboThreshold,
    },
  },
  title: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  tooltip: {
    borderWidth: 0,
    backgroundColor: "#ffffff",
    shadow: false,
    borderRadius: 15,
    hideDelay: 0,
    useHTML: true,
    formatter: function () {
      const { x, y }: any = this;
      const statusLabel = getStatusLabel(y);
      const date = new Date(x).toLocaleDateString();
      return renderToString(
        <Tooltip date={date} statusLabel={statusLabel} y={y} />
      );
    },
  },
  legend: {
    itemStyle: {
      fontWeight: "bold",
      fontSize: "13px",
    },
  },
  xAxis: {
    range: 1 * 30 * 24 * 3600 * 1000, // 1 month
    gridLineWidth: 0,
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
  yAxis: {
    gridLineColor: "rgba(255,255,255,0.15)",
    title: {
      style: {
        textTransform: "uppercase",
      },
    },
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
  rangeSelector: {
    enabled: false,
  },
});

const Tooltip = ({
  statusLabel,
  date,
  y,
}: {
  statusLabel: ReturnType<typeof getStatusLabel>;
  date: string;
  y: number;
}) => {
  let imageSrc;

  switch (statusLabel) {
    case "cautious":
      imageSrc = Cautious;
      break;
    case "chilled":
      imageSrc = Chilled;
      break;
    case "furious":
      imageSrc = Furious;
      break;
    default:
      imageSrc = Grumpy;
  }

  return (
    <div className="text-center bg-white">
      <img width={80} src={imageSrc} />
      <br />
      <span className="text-xl text-accuRankerOrange-500 uppercase">
        {statusLabel}
      </span>
      <br />
      {date}
      <br />
      Rating: {y}
    </div>
  );
};

/**
 *
 * Grump Charting Component
 *
 */
const Chart = ({
  data,
}: {
  data: GrumpData["data"];
  updates: GrumpData["updates"];
}) => {
  const config = {
    ...getBaseConfig(data.length + 100),
    series: [
      {
        name: "Grump index",
        type: "column",
        data: data.slice(0, data.length),
        dataGrouping: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className="h-full">
      <ReactHighstock config={config} />
    </div>
  );
};

export default Chart;

import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../../ui-components/Button";
import classnames from "classnames";
import {
  devices,
  getStatusLabel,
  locales,
  ValueLabel,
} from "../../utils/grumpUtils";
import getGrumpStatus, { GrumpData } from "./GrumpService";
import { useEffect } from "react";
import Chart from "./Chart";
import { GrumpCardFlat } from "../../ui-components/Grump/GrumpCard";
import useWindowSize from "../../utils/resize";
import Rating from "../../ui-components/Grump/Rating";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean;
}

const GridButton: React.FC<ButtonProps> = ({ onClick, active, children }) => (
  <button
    onClick={onClick}
    className={classnames(
      "text-center px-6 py-2 flex flex-row items-center rounded-md justify-center gap-x-2 text-white font-medium",
      {
        "bg-white text-accuRankerPurple-900": active,
        "bg-accuRankerPurpleOpacity-dark border border-accuRankerPurpleOpacity-light  text-accuRankerPurpleOpacity-light":
          !active,
      }
    )}
  >
    {children}
  </button>
);

/**
 *
 * Grump Component
 *
 */

const Grump = () => {
  /** Listener to enable re-render in Highchart */
  const [,] = useWindowSize();
  const [device, setDevice] = useState<typeof devices[number]>(devices[0]);
  const [locale, setLocale] = useState<typeof locales[number]>(locales[0]);
  const [response, setResponse] = useState<GrumpData | null>(null);
  const [responseState, setResponseState] = useState<{
    locale: ValueLabel;
    device: ValueLabel;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getData = async () => {
    const data = await getGrumpStatus(locale.value, device.value);
    if (data.error !== null) {
      setError(data.error);
    } else {
      setResponse(data);
      setResponseState({ device, locale });
    }
  };

  useEffect(() => {
    getData();
  }, [device, locale]);

  return (
    <div>
      {error == null && response == null && (
        <div>
          <StaticImage
            src="../../images/GrumpyPage/chilled.png"
            width={200}
            layout="fixed"
            className="animate-pulse mx-auto my-10"
            placeholder="blurred"
            quality={96}
            alt="Loading Grumpy Data"
          />
        </div>
      )}
      {error && (
        <div className="my-20">
          <h2 className="text-white text-3xl text-center">
            Error while fetching data 😿
          </h2>
          <p className="text-white mx-auto text-center">
            Please try again later
          </p>
        </div>
      )}
      {response && responseState && (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
          {/* Chart Component */}
          <div className="h-full md:col-span-7">
            <Chart updates={response.updates} data={response.data} />
          </div>

          <div className="flex flex-col gap-y-6 md:col-span-5">
            <h2 className="w-full text-left text-white font-sans text-3xl mb-0 font-normal">
              Find Today’s{" "}
              <span className="text-accuRankerOrange-500">Grump Rating</span>
            </h2>
            <GrumpCardFlat
              index={0}
              device={responseState.device.label}
              locale={responseState.locale.label}
              mood={getStatusLabel(response.data.slice(-1)[0].y)}
            />
            {/* Options Component */}
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-3">
              {locales.map(item => (
                <GridButton
                  active={item === locale}
                  onClick={() => setLocale(item)}
                >
                  {item.flag && (
                    <img
                      className="my-auto"
                      src={item.flag}
                      alt={`${item.label} flag`}
                    />
                  )}
                  <span>{item.label}</span>
                </GridButton>
              ))}
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-3">
              {devices.map(item => (
                <GridButton
                  active={item === device}
                  onClick={() => setDevice(item)}
                >
                  {item.icon && <item.icon />}
                  <span>{item.label}</span>
                </GridButton>
              ))}
            </div>
            {/* Newsletter Banner */}
            <div className="flex flex-col xl:flex-row gap-6 items-center xl:items-start bg-accuRankerPurple-500 border-2 border-gray-700 rounded-3xl p-6 gap-y-3">
              <h3 className="text-accuRankerOrange-500 text-center xl:text-left">
                Never miss Google's Mood Swing!
              </h3>
              <Button color="green" className="px-12 py-3" wrap>
                Subscribe to alerts
              </Button>
            </div>
          </div>
        </div>
      )}
      {/* Ratings */}
      <div>
        <Rating />
      </div>
    </div>
  );
};

export default Grump;

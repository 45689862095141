import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

export type GrumpCardProps = {
  /** If the locale have been set, else it is Global */
  locale?: string;
  /** The mood of our Grumpy Cat */
  mood: "cautious" | "furious" | "grumpy" | "chilled";
  /** The index of the day, from 0 to 2, today, yesterday and two days ago */
  index: number;
  /** The fetched device */
  device: string;
};

/**
 * Commented components represent previous Grump Design
 * Leaving them for future usage?
 */
// const DayPill = ({ day }: { day: number }) => (
//   <div
//     className="absolute right-5 top-5 rounded-lg bg-accuRankerOrange-500"
//     style={{
//       padding: "0.1rem 0.5rem",
//       backgroundColor: `${
//         day === 0 ? "#C7DB4E" : day === 1 ? "#82CA69" : "#38E2A5"
//       }`,
//     }}
//   >
//     <p className="m-0 p-0 font-medium">
//       {day === 0 ? "Today" : day === 1 ? "Yesterday" : "2 Days ago"}
//     </p>
//   </div>
// )

// const GrumpCard = ({
//   locale,
//   mood,
//   device = "All Devices",
//   index,
// }: GrumpCardProps) => {
//   /**
//    * Cannot Be Dry, because useStaticQuery does
//    * not work inside Storybook yet (without heavy modification)
//    * and cannot create a storybook component
//    * Source: https://github.com/gatsbyjs/gatsby/issues/26099
//    */
//   const images = useStaticQuery(graphql`
//     {
//       chilled: file(relativePath: { eq: "GrumpyPage/chilled.png" }) {
//         childImageSharp {
//           gatsbyImageData
//         }
//       }
//       grumpy: file(relativePath: { eq: "GrumpyPage/grumpy.png" }) {
//         childImageSharp {
//           gatsbyImageData
//         }
//       }
//       furious: file(relativePath: { eq: "GrumpyPage/furious.png" }) {
//         childImageSharp {
//           gatsbyImageData
//         }
//       }
//       cautious: file(relativePath: { eq: "GrumpyPage/cautious.png" }) {
//         childImageSharp {
//           gatsbyImageData
//         }
//       }
//     }
//   `)

//   return (
//     <div className="flex flex-col relative max-w-sm items-start bg-accuRankerPurple-500 border-2 border-gray-700 rounded-3xl p-6 pb-2 gap-y-3">
//       <DayPill day={index} />
//       <GatsbyImage
//         image={images[mood].childImageSharp.gatsbyImageData}
//         className="w-24 h-24"
//         alt={`${mood} tiger image`}
//       />
//       <div>
//         <p className="text-gray-400 mb-2 author-card-rale">
//           {locale ? locale : "Global"} | {device}
//         </p>
//         <h2 className="text-white text-3xl font-sans font-normal">
//           Google is <span className="capitalize">{mood}</span>
//         </h2>
//       </div>
//     </div>
//   )
// }

const DayPill = ({ day }: { day: number }) => (
  <div
    className="rounded-lg bg-accuRankerOrange-500"
    style={{
      padding: "0.1rem 0.5rem",
      backgroundColor: `${
        day === 0 ? "#C7DB4E" : day === 1 ? "#82CA69" : "#38E2A5"
      }`,
    }}
  >
    <p className="m-0 p-0 font-medium">
      {day === 0 ? "Today" : day === 1 ? "Yesterday" : "2 Days ago"}
    </p>
  </div>
);

export const GrumpCardFlat = ({
  locale,
  mood,
  device = "All Devices",
  index,
}: GrumpCardProps) => {
  /**
   * Cannot Be Dry, because useStaticQuery does
   * not work inside Storybook yet (without heavy modification)
   * and cannot create a storybook component
   * Source: https://github.com/gatsbyjs/gatsby/issues/26099
   */
  const images = useStaticQuery(graphql`
    {
      chilled: file(relativePath: { eq: "GrumpyPage/chilled.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      grumpy: file(relativePath: { eq: "GrumpyPage/grumpy.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      furious: file(relativePath: { eq: "GrumpyPage/furious.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      cautious: file(relativePath: { eq: "GrumpyPage/cautious.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div className="grid grid-cols-12 gap-x-6 items-start bg-accuRankerPurple-500 border-2 border-gray-700 rounded-3xl p-4 gap-y-3">
      <GatsbyImage
        image={images[mood].childImageSharp.gatsbyImageData}
        className="w-20 h-20 col-span-4 md:col-span-3"
        alt={`${mood} tiger image`}
      />
      <div className="col-span-8 md:col-span-9 flex flex-col h-full gap-x-6 justify-evenly">
        <div className="flex flex-col items-start md:flex-row md:items-center gap-x-4 gap-y-2">
          <DayPill day={index} />{" "}
          <span className="text-gray-400 author-card-rale">
            {locale ? locale : "Global"} | {device}
          </span>
        </div>
        <div>
          <h2 className="text-white text-3xl font-sans p-0 m-0 font-normal">
            Google is <span className="capitalize">{mood}</span>
          </h2>
        </div>
      </div>
    </div>
  );
};
